<template>
  <div>
    <Sidebar />
    <div class="ps-2 mt-7 pt-7">
      <UserName />
    </div>
    <HeaderNavigasi  />

    <div style="margin-bottom: 5em">
      <div v-if="isLoading">
        <v-container>
          <v-row>
            <v-col
              v-for="i in 10"
              :key="i"
              cols="4"
              lg="2"
              md="2"
              sm="2"
              active="1"
              style="padding: 5px 4px 0px 4px;"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-else>
        <div
          v-if="listRecomend.length === 0"
          class="parent-class text-center d-flex justify-center"
        >
          <div style="margin-top: 10%">
            <h3>{{ $t("message.no-recommend-product") }}</h3>
          </div>
        </div>

        <v-container>
          <v-row style="padding: 0px;">
            <v-col
              v-for="(list, id) in listRecomend"
              :key="id"
              cols="4"
              lg="2"
              md="2"
              sm="2"
              active="1"
              style="padding: 5px 4px 10px 4px;"
            >
              <Product :data="list" />
            </v-col>
          </v-row>

          <div class="text-start">
            <v-pagination
              color="#ff0090"
              v-model="pageRecomend"
              :length="listRecomendHeader.last_page"
              :total-visible="5"
              class="mt-6"
              @input="nextRecomend"
            ></v-pagination>
          </div>
        </v-container>
        <!-- <infinite-loading
          @infinite="infiniteHandler"
          infinite-scroll-distance="1"
          spinner="waveDots"
          ref="infiniteLoading"
        >
          <template slot="no-more"
            >指示されたデータが全てロードしました</template
          >
        </infinite-loading> -->
      </div>
      <div>
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Product from "../components/Product.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";
import Back from "../components/Back.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import Cashier from "../components/Cashier.vue";
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import {
  mdiArrowLeft,
  mdiMagnify,
  mdiCart,
  mdiHeartOutline,
  mdiStore,
} from "@mdi/js";
// import InfiniteLoading from "vue-infinite-loading";
export default {
  name: "RecomendProduct",
  components: {
    Sidebar,
    Product,
    HeaderNavigasi,
    BackComponent: Back,
    Skeleton,
    CategoryFooter,
    Cashier,
    UserName: User,
    // InfiniteLoading,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      pageRecomend: 1,
      isLoading: false,
      is_loadingmore: true,
      recomend: [],
    };
  },
  beforeMount(){
    window.scrollTo(0,0)
  },
  watch: {
    // listRecomend: {
    //   handler() {
    //     this.pageRecomend = parseInt(
    //       this.$store.state.itemRecomend_module.itemRecomendHeaderResult
    //         .current_page
    //     );
    //   },
    //   deep: true,
    // },
    // pageRecomend() {
    //   // this.infiniteHandler()
    // },
  },
  methods: {
    // async infiniteHandler($state) {
    //   this.pageRecomend = this.$store.state.itemRecomend_module.current_page;
    //   let lastPage = this.$store.state.itemRecomend_module.last_page;
    //   if (this.pageRecomend < lastPage) {
    //     this.pageRecomend++;
    //     await this.$store.dispatch("itemRecomend_module/itemRecomendPage", {
    //       page: this.pageRecomend,
    //     });
    //     $state.loaded();
    //   } else {
    //     $state.complete();
    //   }
    // },
    async itemRecomend() {
      this.isLoading = true;
      this.pageRecomend = this.$store.state.itemRecomend_module.current_page;
      let data;
      data = await this.$store.dispatch(
        "itemRecomend_module/itemRecomendPage",
        {
          page: this.pageRecomend,
        }
      );
      this.isLoading = false;
      return data;
    },
    async nextRecomend() {
      window.scrollTo(0, 0);
      this.isLoading = true;

      let res = await this.$store.dispatch(
        "itemRecomend_module/itemRecomendPage",
        {
          page: this.pageRecomend,
        }
      );
      this.isLoading = false;
      return res;
    },
    // onScroll() {
    //   window.onscroll = async () => {
    //     let bottomOfWindow =
    //       document.documentElement.scrollTop + window.innerHeight >=
    //       document.documentElement.offsetHeight;
    //     if (bottomOfWindow) {
    //       this.pageRecomend++;
    //       if (this.listRecomendHeader < this.pageRecomend) {
    //         return;
    //       } else {
    //         this.is_loadingmore = true;
    //         if (this.$route.name === "RecomendProduct") {
    //           await this.$store.dispatch(
    //             "itemRecomend_module/itemRecomendPage",
    //             {
    //               page: this.pageRecomend,
    //             }
    //           );
    //         }
    //       }
    //     }
    //   };
    // },
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.onScroll);
  // },
  mounted() {
    // this.$nextTick(function() {
    //   window.addEventListener("scroll", this.onScroll);
    //   this.onScroll(); // needed for initial loading on page
    // });
  },
  computed: {
    listRecomend() {      
      return this.$store.state.itemRecomend_module.itemRecPage;
    },
    listRecomendHeader() {
      return this.$store.state.itemRecomend_module.itemRecomendHeaderResult;
    },
    // resetData(){
    //   if (this.$route.name == 'RecomendProduct') {
    //     this.$store.state.itemRecomend_module.itemRecomendResult = []
    //   }
    // }
  },
  created() {
    this.itemRecomend();
  },
};
</script>
<style>
.parent-style:hover {
  box-shadow: 8px 8px 5px gray;
  transition: all 0.3s;
}
.v-main__wrap {
  background-color: #ffffff;
  margin-top: 1px;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  height: 25px;
}
</style>
